// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/recipe-post.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-recipes-js": () => import("./../../../src/templates/recipes.js" /* webpackChunkName: "component---src-templates-recipes-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-weekmenu-post-js": () => import("./../../../src/templates/weekmenu-post.js" /* webpackChunkName: "component---src-templates-weekmenu-post-js" */),
  "component---src-templates-weekmenus-js": () => import("./../../../src/templates/weekmenus.js" /* webpackChunkName: "component---src-templates-weekmenus-js" */)
}

